













import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import SettingsTabs from '@/components/vendors/VendorSettingsTabs.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useVendorsStore } from '@/store/vendors-store';
import { Vendor } from '@/model/vendor';
import { useUsersStore } from '@/store/users-store';
import { useCountriesStore } from '@/store/countries-store';

export default Vue.extend({
  components: { LoadingIndicator, SettingsTabs },
  computed: {
    ...mapStores(useVendorsStore, useUsersStore, useCountriesStore),
    sendingData(): boolean {
      return this.vendorsStore.saving;
    },
    vendor(): Vendor {
      return this.vendorsStore.currentEntity;
    },
    isFetchingVendor(): boolean {
      return this.vendorsStore.fetching;
    },
    loggedInVendorId(): number | null {
      return this.usersStore.loggedInVendorId;
    }
  },
  created() {
    this.countriesStore.findAll();
    if (this.loggedInVendorId !== null) {
      this.vendorsStore.getUserVendor();
    }
  }
});
